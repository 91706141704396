import React, { createContext, ReactNode, useContext, useState, useMemo } from 'react';
import { PaypalMessageFields } from '@/components/AppIntegrations/types';

interface PaypalMessageContext extends PaypalMessageFields {
    isScriptLoaded: boolean;
    setIsScriptLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaypalMessageContext = createContext({} as PaypalMessageContext);

const PaypalMessageContextProvider = ({
    children,
    initState,
}: {
    children: ReactNode;
    initState: PaypalMessageFields;
}) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    const contextValue = useMemo(
        () => ({
            ...initState,
            isScriptLoaded,
            setIsScriptLoaded,
        }),
        [initState, isScriptLoaded],
    );

    return <PaypalMessageContext.Provider value={contextValue}>{children}</PaypalMessageContext.Provider>;
};

export default PaypalMessageContextProvider;

export const usePaypalMessageContext = () => useContext(PaypalMessageContext);
